@use '~@ag-grid-community/styles' as ag;

@import 'bootstrap4_variable_overrides';
@import 'bootstrap4';
@import 'custom_variables';
@import '../fontawesomeCustom/scss/fontawesome';
@import '../fontawesomeCustom/scss/solid';
@import '../fontawesomeCustom/scss/sharp-regular';
@import '../fontawesomeCustom/scss/sharp-solid';
@import '../fontawesomeCustom/scss/sharp-light';
@import '../fontawesomeCustom/scss/custom-icons';
@import 'source_sans_pro';
@import 'layout';
@import 'text';
@import 'components';
@import 'mobile_specific';
@import 'formula_editor';
@import 'timebar';
@import 'editor';
@import '~react-circular-progressbar/dist/styles.css';
@import 'baseColors';
@import 'themeColors';
@import 'bootstrap4_components';
@import 'ckeditor';
@import 'tableViewer';
@import 'dateRangeLabel';
@import 'iframeContent';
@import 'toastify';
@import 'interactiveContent';
@import 'ag_grid';
@import 'dashboard';

@include ag.grid-styles();
// qomponents Css
@import '@seeqdev/qomponents/dist/styles.css';

.mainHeader {
  min-height: 43px;
  max-height: 43px;
  height: 43px;

  .sq-navbar-default {
    &:hover {
      // Use rollover text on hover
      cursor: pointer;
    }
  }
}

// force chrome & safari to always show a scrollbar
// this ensures that the user knows when there is extra content in a pane and
// prevents the scrollbar from covering up content while the user is scrolling
::-webkit-scrollbar {
  width: 9px;
  height: 8px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.05);
}

.mainFooter {
  padding: 4px 10px;
}

.navbar-brand {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  cursor: default;
}

/* Used to override cursor when dragging vertical scale on Trend */
.globalCursorNorthSouth * {
  cursor: ns-resize !important;
}

/* Used to override cursor when dragging horizontal scale on Trend */
.globalCursorEastWest * {
  cursor: ew-resize !important;
}

/* Used to override cursor when dragging timebar */
.globalCursorPointer * {
  cursor: pointer !important;
}

.as-sortable-dragging {
  overflow: hidden; // Prevents scrollbars when dragging an element
}

.tourInterceptor {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

header h4 {
  font-weight: normal;
  text-align: center;
}

[ng\:cloak],
[ng-cloak],
.ng-cloak {
  display: none;
}

.hide-ms-clear {
  &::-ms-clear {
    width: 0;
    height: 0;
  }
}

.forceFontAwesome {
  font-family: 'Font Awesome 6 Sharp' !important;
}

// Make question-circle icons appear 'inline' with text
.fa-question-circle {
  line-height: 1em;
}
