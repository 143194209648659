.iframeContent .iframeContentContainer iframe {
  border: 1px solid #cccccc;
  width: 100%;
  height: 100%;
}

.iframeContentPopover {
  max-width: none;
  width: 500px;

  .was-validated .form-control {
    background-image: none;
  }

  .arrow {
    display: none;
  }
}

.ck.ck-editor__editable {
  .iframeContent {
    .borderTrigger {
      opacity: 0;
      position: absolute;
      top: -22px;
      right: calc(-1 * var(--ck-widget-outline-thickness));
      width: 20px;
      height: 22px;
      background-color: var(--ck-color-widget-hover-border);
      border-width: var(--ck-widget-outline-thickness);
      border-style: solid;
      border-color: var(--ck-color-widget-hover-border);
      border-bottom: 0px;
      transition: opacity var(--ck-widget-handler-animation-duration) var(--ck-widget-handler-animation-curve);
      cursor: pointer;

      i.fa {
        color: white;
        margin-left: 2px;
      }

      // OFFSET classes to use when multple border triggers are added to the same component
      &.offset1 {
        margin-right: 25px;
      }

      &.offset2 {
        margin-right: 50px;
      }

      &.offset3 {
        margin-right: 75px;
      }

      &.offset4 {
        margin-right: 75px;
      }
    }

    &:hover .borderTrigger {
      opacity: 100;
    }

    &.ck-widget_selected .borderTrigger {
      opacity: 100;
      background-color: var(--ck-color-focus-border);
      border-color: var(--ck-color-focus-border);
    }
  }

  &.ck-blurred .iframeContent .borderTrigger {
    opacity: 0;
  }

  .iframeContent .borderTrigger.alwaysVisible {
    opacity: 100;
    border-width: 0px;
    background-color: transparent;
    top: 0px;

    i.fa {
      color: black;
      margin-left: 4px;
    }
  }
}
