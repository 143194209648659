/* specific styling to accommodate for small screen sizes like mobile phones**/
@media (max-width: 768px) {
  .modal-dialog,
  .report-content-modal,
  .modal-dialog.report-content-modal {
    max-width: 95% !important;
    min-width: 100px !important;
    padding-left: 5px;
  }

  .thickVerticalDividerMobile {
    min-width: 12px !important;
    max-width: 12px !important;
  }

  .thickHorizontalDividerMobile {
    min-height: 12px !important;
    max-height: 12px !important;
  }

  html,
  body,
  .fullViewport {
    height: 100%;
  }

  .mobileScrollable {
    overflow: auto;
  }

  .homeScreenSearchBar {
    .input-group {
      flex-flow: nowrap;
    }
  }

  .mobileNoScroll {
    overflow: hidden !important;
  }
}
