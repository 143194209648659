#dashboardToolbar {
  max-height: 35px;
  min-height: 35px; // Needed because btn elements inside are float so they take up no vertical space in Chrome
  border-top: 0;
}

.squareBtn {
  height: 27px;
  width: 27px;
  padding-left: 7px;
}

.dashItemActions {
  position: absolute;
  top: 5px;
  right: 3px;
  z-index: 300;
}

.dashRefresh {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.dashboardWarning {
  position: absolute;
  top: 25px;
  width: 100%;
  overflow-y: auto;
  padding: 10px;
  max-height: 80%;
  z-index: 200;
}

.dashboardItem {
  border-radius: 8px;
  cursor: move;

  .width-auto {
    width: 100%;
  }
}

.dashboardItemScroll {
  overflow: auto;
  height: 100%;
  padding-top: 3px;
}

.dashboardFillWidthFullHeight {
  width: 100%;
  height: 100%;
}

.dashboardPDF {
  width: 100% !important;
}

.dashboardItemToolBar {
  height: 26px;
  min-height: 26px;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 2px;
}

.dashboardTitle {
  @media print {
    margin-top: 4px;
  }
  display: inline-block;
}

.dashboardTitleEditing {
  width: 100% !important;
}

.dashboardGrid {
  @media print {
    font-size: 0.5rem !important;
  }
}

.dashboardToolbar {
  position: absolute;
  width: 100%;
  top: -43px;
}

.noBorder {
  border: solid 1px transparent !important;
}

.dashboardGrid {
  // this ensures that the CKEditor inside the dashboard tile takes up the entire height of the tile
  .editorContainer > div {
    height: 100%;
  }

  .ck-editor__editable {
    box-shadow: none !important;
  }

  // this makes sure that there is no awkward spacing between the top of the editor and the top of the tile
  .ck.ck-editor__editable_inline > :last-child {
    margin-bottom: 0 !important;
  }

  .ck.ck-editor__editable_inline > :first-child {
    margin-top: 0 !important;
  }
}
