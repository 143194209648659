/* Seeq branding */

$sq-report-color: #39516b;
$sq-analysis-color: #427c63;
$sq-datalab-color: #f26722;

$sq-text-on-gray: #000000;

$sq-tools-background: #fff;
$sq-tools-background_dark: rgb(36, 37, 37);
$sq-header-color_dark: rgb(21, 99, 81);
$sq-footer-color_dark: rgb(36, 45, 49);
$sq-footer-text-color_dark: rgb(203, 196, 186);

$white: #ffffff;
$black: #000000;
$red: #f00;

$sq-darker-primary-topic: #12385b;
$sq-darker-primary-topic_dark: #12385b;
$sq-topic-header_dark: rgb(49, 80, 104);
$sq-dark-primary-topic: #2a5c84;
$sq-dark-primary-topic_dark: rgb(145, 180, 203);
$sq-link-color-topic: #2a5c84;
$sq-link-color-topic_dark: rgb(145, 180, 203);
$sq-icon-over-topic: #0491c2;
$sq-icon-over-topic_dark: #0491c2;
$sq-highlight-topic: #eef6fd;
$sq-highlight-topic_dark: rgb(40, 42, 42);
$sq-light-color-topic: #edf7f6;
$sq-light-color-topic_dark: $sq-footer-color_dark;

$sq-darker-primary-analysis: #124734;
$sq-darker-primary-analysis_dark: rgb(30, 64, 50);
$sq-dark-primary-analysis: #007960;
$sq-dark-primary-analysis_dark: rgb(31, 207, 166);
$sq-link-color-analysis: #007960;
$sq-link-color-analysis_dark: rgb(31, 207, 166);
$sq-icon-over-analysis: #00a059;
$sq-icon-over-analysis_dark: #00a059;
$sq-icon-activated-analysis: #00cb72;
$sq-icon-activated-analysis_dark: #00cb72;
$sq-icon-activated-topic: #41a5e1;
$sq-icon-activated-topic_dark: #41a5e1;
$sq-highlight-analysis: #eefef5;
$sq-highlight-analysis_dark: #eefef5;
$sq-light-color-analysis: #edf7f6;
$sq-light-color-analysis_dark: #edf7f6;

$sq-darker-primary-datalab: #c1480b;
$sq-dark-primary-datalab: #f26722;
$sq-link-color-datalab: #f26722;
$sq-icon-over-datalab: #f47b3e;
$sq-highlight-datalab: #edf7f6;
$sq-light-color-datalab: #edf7f6;

$sq-white: #fff;
$sq-white_dark: rgb(36, 37, 37);
$sq-black: #000;
$sq-black_dark: #fff;
$sq-darker-gray: #e7e7e7;
$sq-darker-gray_dark: rgb(49, 51, 50);
$sq-disabled-gray: #dde1e3;
$sq-disabled-gray_dark: rgb(67, 69, 70);
$sq-darkest-gray: #e6e7e8;
$sq-darkest-gray_dark: rgb(65, 67, 68);
$sq-darkish-gray: #b7b7b7;
$sq-darkish-gray_dark: #646262;
$sq-transparent-gray: rgba(207, 201, 202, 0.1);
$sq-dark-gray: #e2e2e2;
$sq-dark-gray_dark: rgb(66, 69, 69);
$sq-light-gray: #f1f5f7;
$sq-light-gray_dark: rgb(36, 45, 49);
$sq-lightest-gray: #f1f5f7;
$sq-lightest-gray_dark: rgb(66, 69, 69);
$sq-overlay-gray: #eeedee;
$sq-overlay-gray_dark: rgb(36, 37, 37);
$sq-side-panel-text-color: #999999;
$sq-side-panel-text-color_dark: #999999;
$sq-text-color: #3a3a3a;
$sq-text-color_dark: rgb(194, 188, 176);
$sq-fairly-dark-gray: #606061;
$sq-fairly-dark-gray_dark: rgb(172, 164, 150);
$sq-gray-text: #869298;
$sq-gray-text_dark: #869298;
$sq-table-highlight: #f9f9f9;
$sq-table-highlight_dark: rgb(39, 40, 40);
$sq-backdrop: #c0c0c008;
$sq-backdrop_dark: rgb(36, 37, 37);
$sq-worksheetspanel-gray: #f1f3f4;
$sq-worksheetspanel-gray_dark: rgb(42, 44, 44);
$sq-border-color: $sq-darkest-gray;
$sq-border-color_dark: rgb(70, 73, 73);

$sq-status-error-color: #d9534f;
$sq-status-error-color_dark: #d9534f;
$sq-status-progress-color: #3a3a3a;
$sq-status-progress-color_dark: #3a3a3a;
$sq-status-warning-color: #f0ad4e;
$sq-status-warning-color_dark: #f0ad4e;
$sq-status-good-color: #0c684c;
$sq-status-good-color_dark: #0c684c;
$sq-status-info-color: #0491c2;
$sq-status-info-color_dark: #0491c2;

$sq-success-color: #72c02c;
$sq-active-color: #fcb426;
$sq-text-warning-color: #f0ad4e;
$sq-text-dark-warning-color: #664d03;
$sq-danger-color: #d9534f;
$sq-bg-success-color: #dff0d8;
$sq-bg-warning-color: #fff3cd;
$sq-bg-danger-color: #f2dede;
$sq-bg-warning-border-color: #ffecb5;

$sq-success-color: #72c02c;
$sq-success-color_dark: #72c02c;
$sq-active-color: #fcb426;
$sq-active-color_dark: #fcb426;
$sq-text-warning-color: #f0ad4e;
$sq-text-warning-color_dark: #f0ad4e;
$sq-text-dark-warning-color: #664d03;
$sq-text-dark-warning-color_dark: #664d03;
$sq-danger-color: #d9534f;
$sq-danger-color_dark: #d9534f;
$sq-bg-success-color: #dff0d8;
$sq-bg-success-color_dark: #dff0d8;
$sq-bg-warning-color: #fff3cd;
$sq-bg-warning-color_dark: #fff3cd;
$sq-bg-danger-color: #f2dede;
$sq-bg-danger-color_dark: #f2dede;
$sq-bg-warning-border-color: #ffecb5;
$sq-bg-warning-border-color_dark: #ffecb5;

$backgroundcolor-trace1: #dbffff;
$backgroundcolor-trace1_dark: rgb(36, 45, 49);
$backgroundcolor-trace2: #cefdff;
$backgroundcolor-trace2_dark: $sq-tools-background_dark;
$backgroundcolor-debug1: #f4ffff;
$backgroundcolor-debug1_dark: rgb(18, 64, 63);
$backgroundcolor-debug2: #e7ffff;
$backgroundcolor-debug2_dark: rgb(19, 71, 69);
$backgroundcolor-info2: #f8f8f8;
$backgroundcolor-info2_dark: rgb(39, 41, 40);
$backgroundcolor-warn1: #ffffe8;
$backgroundcolor-warn1_dark: rgb(63, 62, 17);
$backgroundcolor-warn2: #ffffdb;
$backgroundcolor-warn2_dark: #ffffdb;
$backgroundcolor-error1: #ffede9;
$backgroundcolor-error1_dark: rgb(68, 25, 15);
$backgroundcolor-error2: #ffe0dc;
$backgroundcolor-error2_dark: rgb(75, 22, 15);
$backgroundcolor-block: #f8f8f8;
$backgroundcolor-block_dark: #f8f8f8;
$backgroundcolor-selected: #e5ecfb;
$backgroundcolor-selected_dark: #000;
$backgroundcolor-hover: #fbcf7c;
$backgroundcolor-hover_dark: #fbcf7c;
$backgroundcolor-xcursor: '#eaf3f4';
$backgroundcolor-xcursor_dark: '#242d31';
$backgroundcolor-table-highlight-green: #eef8f4;
$backgroundcolor-table-highlight-green_dark: rgb(33, 54, 47);
$backgroundcolor-table-highlight: #fcf8e3;
$backgroundcolor-table-highlight_dark: $sq-footer-color_dark;
$backgroundcolor-table-highlight-hover: #faf2cc;
$backgroundcolor-highlight: darken($backgroundcolor-table-highlight, 10%);
$backgroundcolor-highlight-hover: darken($backgroundcolor-table-highlight-hover, 10%);
$bordercolor: $sq-darkest-gray;
$bordercolor-selected: darken($backgroundcolor-selected, 10%);
$bordercolor-hover: darken($backgroundcolor-hover, 10%);

$sq-light-thin-border: 1px solid rgba($sq-light-gray, 0.2);

$box-border-radius: 0px;
$screen-md-height: 850px;

$sq-animation-time-ms: 400ms; // keep in sync with ANIMATION_TIME_MS variable from app.constants

$fa-font-path: '../fontawesomeCustom/webfonts';

/**
These are the "Theme Colors" used to visually differentiate the Analysis and Topic displays.
The $themeColors map is used by themeColors.scss to create theme specific css.
The "dark" color is the color of the side panel and header bar,
the "darker" color is hardly used (only to accentuate the Seeq logo in the left corner and some borders)
the "icon" color is used on hover of icons
the "light" color is only used for the icons in the header bar.
 **/
$themeColors: (
  analysis: (
    darker: $sq-darker-primary-analysis,
    dark: $sq-dark-primary-analysis,
    icon: $sq-icon-over-analysis,
    light: $sq-light-color-analysis,
    link: $sq-link-color-analysis,
    highlight: $sq-highlight-analysis,
    header: $sq-dark-primary-analysis,
    footerTextColor: $sq-dark-primary-analysis,
    itemRow: $sq-light-color-analysis,
    reactSelect: $sq-dark-primary-analysis,
    reactSelectHover: $sq-dark-primary-analysis,
    highlightBackground: $sq-light-color-analysis,
  ),
  analysis_dark: (
    darker: $sq-darker-primary-analysis,
    dark: $sq-dark-primary-analysis_dark,
    icon: $sq-icon-over-analysis,
    light: $sq-light-color-analysis,
    link: $sq-link-color-analysis_dark,
    highlight: $sq-highlight-analysis,
    header: $sq-header-color_dark,
    footerTextColor: $sq-footer-text-color_dark,
    itemRow: $backgroundcolor-table-highlight-green_dark,
    reactSelect: $sq-darker-primary-analysis,
    reactSelectHover: $backgroundcolor-table-highlight-green_dark,
    highlightBackground: $sq-transparent-gray,
  ),
  topic: (
    darker: $sq-darker-primary-topic,
    dark: $sq-dark-primary-topic,
    icon: $sq-icon-over-topic,
    light: $sq-light-color-topic,
    link: $sq-link-color-topic,
    highlight: $sq-highlight-topic,
    header: $sq-dark-primary-topic,
    footerTextColor: $sq-dark-primary-topic,
    itemRow: $sq-light-color-topic,
    configHeader: $sq-lightest-gray,
  ),
  topic_dark: (
    darker: $sq-darker-primary-topic_dark,
    dark: $sq-dark-primary-topic_dark,
    icon: $sq-icon-over-topic_dark,
    light: $sq-light-color-topic_dark,
    link: $sq-link-color-topic_dark,
    highlight: $sq-highlight-topic_dark,
    header: $sq-topic-header_dark,
    footerTextColor: $sq-dark-primary-topic_dark,
    itemRow: $sq-footer-color_dark,
    configHeader: $sq-footer-color_dark,
  ),
  datalab: (
    darker: $sq-darker-primary-datalab,
    dark: $sq-dark-primary-datalab,
    icon: $sq-icon-over-datalab,
    light: $sq-light-color-datalab,
    link: $sq-link-color-datalab,
    highlight: $sq-highlight-datalab,
    header: $sq-dark-primary-datalab,
    footerTextColor: $sq-dark-primary-datalab,
  ),
);

$fixed-header-table-z-index: 100;

$darkModeColors: (
  tw-dark: (
    sq-tools-background: $sq-tools-background_dark,
    sq-white: $sq-white_dark,
    sq-black: $sq-black_dark,
    sq-darker-gray: $sq-darker-gray_dark,
    sq-disabled-gray: $sq-disabled-gray_dark,
    sq-darkest-gray: $sq-darkest-gray_dark,
    sq-darkish-gray: $sq-darkish-gray_dark,
    sq-dark-gray: $sq-dark-gray_dark,
    sq-light-gray: $sq-light-gray_dark,
    sq-lightest-gray: $sq-lightest-gray_dark,
    sq-overlay-gray: $sq-overlay-gray_dark,
    sq-side-panel-text-color: $sq-side-panel-text-color_dark,
    sq-text-color: $sq-text-color_dark,
    sq-fairly-dark-gray: $sq-fairly-dark-gray_dark,
    sq-gray-text: $sq-gray-text_dark,
    sq-table-highlight: $sq-table-highlight_dark,
    sq-backdrop: $sq-backdrop_dark,
    sq-worksheetspanel-gray: $sq-worksheetspanel-gray_dark,
    sq-status-error-color: $sq-status-error-color_dark,
    sq-status-progress-color: $sq-status-progress-color_dark,
    sq-status-warning-color: $sq-status-warning-color_dark,
    sq-status-good-color: $sq-status-good-color_dark,
    sq-status-info-color: $sq-status-info-color_dark,
    sq-success-color: $sq-success-color_dark,
    sq-active-color: $sq-active-color_dark,
    sq-text-warning-color: $sq-text-warning-color_dark,
    sq-text-dark-warning-color: $sq-text-dark-warning-color_dark,
    sq-danger-color: $sq-danger-color_dark,
    sq-bg-success-color: $sq-bg-success-color_dark,
    sq-bg-warning-color: $sq-bg-warning-color_dark,
    sq-bg-danger-color: $sq-bg-danger-color_dark,
    sq-bg-warning-border-color: $sq-bg-warning-border-color_dark,
    sq-footer-color: $sq-footer-color_dark,
    sq-hover-select: $sq-footer-color_dark,
    backgroundcolor-table-highlight-green: $backgroundcolor-table-highlight-green_dark,
    border-color: $sq-border-color_dark,
    hover-gray: $sq-table-highlight_dark,
    home-screen-gray: $sq-footer-color_dark,
    backgroundcolor-trace1: $backgroundcolor-trace1_dark,
    backgroundcolor-trace2: $backgroundcolor-trace2_dark,
    backgroundcolor-debug1: $backgroundcolor-debug1_dark,
    backgroundcolor-debug2: $backgroundcolor-debug2_dark,
    backgroundcolor-info2: $backgroundcolor-info2_dark,
    backgroundcolor-warn1: $backgroundcolor-warn1_dark,
    backgroundcolor-warn2: $backgroundcolor-warn2_dark,
    backgroundcolor-error1: $backgroundcolor-error1_dark,
    backgroundcolor-error2: $backgroundcolor-error2_dark,
    backgroundcolor-block: $backgroundcolor-block_dark,
    backgroundcolor-selected: $backgroundcolor-selected_dark,
    backgroundcolor-hover: $backgroundcolor-hover_dark,
    backgroundcolor-xcursor: $backgroundcolor-xcursor_dark,
    sq-background: $sq-white_dark,
    backgroundcolor-table-highlight: $backgroundcolor-table-highlight_dark,
  ),
  light: (
    sq-tools-background: $sq-tools-background,
    sq-white: $sq-white,
    sq-black: $sq-black,
    sq-darker-gray: $sq-darker-gray,
    sq-disabled-gray: $sq-disabled-gray,
    sq-darkest-gray: $sq-darkest-gray,
    sq-darkish-gray: $sq-darkish-gray,
    sq-dark-gray: $sq-dark-gray,
    sq-light-gray: $sq-light-gray,
    sq-lightest-gray: $sq-lightest-gray,
    sq-overlay-gray: $sq-overlay-gray,
    sq-side-panel-text-color: $sq-side-panel-text-color,
    sq-text-color: $sq-text-color,
    sq-fairly-dark-gray: $sq-fairly-dark-gray,
    sq-gray-text: $sq-gray-text,
    sq-table-highlight: $sq-table-highlight,
    sq-backdrop: $sq-backdrop,
    sq-worksheetspanel-gray: $sq-worksheetspanel-gray,
    sq-status-error-color: $sq-status-error-color,
    sq-status-progress-color: $sq-status-progress-color,
    sq-status-warning-color: $sq-status-warning-color,
    sq-status-good-color: $sq-status-good-color,
    sq-status-info-color: $sq-status-info-color,
    sq-success-color: $sq-success-color,
    sq-active-color: $sq-active-color,
    sq-text-warning-color: $sq-text-warning-color,
    sq-text-dark-warning-color: $sq-text-dark-warning-color,
    sq-danger-color: $sq-danger-color,
    sq-bg-success-color: $sq-bg-success-color,
    sq-bg-warning-color: $sq-bg-warning-color,
    sq-bg-danger-color: $sq-bg-danger-color,
    sq-bg-warning-border-color: $sq-bg-warning-border-color,
    sq-footer-color: $sq-lightest-gray,
    sq-hover-select: $sq-light-gray,
    backgroundcolor-table-highlight-green: $backgroundcolor-table-highlight-green,
    border-color: $sq-border-color,
    hover-gray: $sq-light-gray,
    home-screen-gray: $sq-light-gray,
    backgroundcolor-trace1: $backgroundcolor-trace1,
    backgroundcolor-trace2: $backgroundcolor-trace2,
    backgroundcolor-debug1: $backgroundcolor-debug1,
    backgroundcolor-debug2: $backgroundcolor-debug2,
    backgroundcolor-info2: $backgroundcolor-info2,
    backgroundcolor-warn1: $backgroundcolor-warn1,
    backgroundcolor-warn2: $backgroundcolor-warn2,
    backgroundcolor-error1: $backgroundcolor-error1,
    backgroundcolor-error2: $backgroundcolor-error2,
    backgroundcolor-block: $backgroundcolor-block,
    backgroundcolor-selected: $backgroundcolor-selected,
    backgroundcolor-hover: $backgroundcolor-hover,
    backgroundcolor-xcursor: $backgroundcolor-xcursor,
    sq-background: transparent,
    backgroundcolor-table-highlight: $backgroundcolor-table-highlight,
  ),
);
