.ag-cell {
  display: table !important;
}

.ag-cell-wrapper {
  vertical-align: middle !important;
}

.ag-header-cell {
  padding: 0 !important;
}

.ag-cell-value,
.ag-header-cell {
  [data-testid='tableBuilderTextHeader'],
  [data-testid='tableBuilderDataCell'],
  [data-testid='tableBuilderTextCell'] {
    height: 100%;
  }
}

.ag-icon {
  font-family: 'FontAwesome' !important;
  font-size: 10px !important;
}

.ag-icon-arrows::before {
  content: '\f047' !important;
}

.ag-icon-not-allowed::before {
  content: '\f05e' !important;
}

.ag-dnd-ghost-icon {
  margin-right: 3px;
}

// The group icon at the top left of the grouping bar
.ag-icon.ag-icon-group {
  margin-left: 5px;
  margin-right: 5px !important;

  &:before {
    @extend .fa-diagram-subtask;
    @extend .fa-sharp;
    @extend .fa-regular;
  }
}

// Drag handles in the grouping bar
.ag-icon.ag-icon-grip {
  margin-right: 1px;

  &:before {
    @extend .fa-arrows;
    @extend .fa-sharp;
    @extend .fa-regular;
  }
}

// Remove icon in the grouping bar
.ag-icon.ag-icon-cancel {
  margin-left: 3px;
  margin-right: 3px;
  padding-top: 2px;

  &:before {
    @extend .fa-remove;
    @extend .fa-sharp;
    @extend .fa-regular;
  }
}

// Column separator in grouping bar
.ag-icon.ag-icon-small-right {
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 2px;

  &:before {
    padding-bottom: 2px;
    content: $breadcrumb-separator;
    font-size: 175%;
  }
}

// Closed icon in grouped row
.ag-icon.ag-icon-tree-closed {
  margin-right: 2px;
  padding-top: 2px;

  &:before {
    @extend .fa-chevron-right;
    @extend .fa-sharp;
    @extend .fa-regular;
  }
}

// Opened icon in grouped row
.ag-icon.ag-icon-tree-open {
  margin-right: 2px;
  padding-top: 2px;

  &:before {
    @extend .fa-chevron-down;
    @extend .fa-sharp;
    @extend .fa-regular;
  }
}

@for $num from 1 through 50 {
  .ag-row-group-indent-#{$num} {
    margin-left: #{10 * $num}px;
  }
}

// The columns in the column drop zone
span.ag-column-drop-cell {
  border-radius: 45%;
  margin: 2px;
  padding: 3px;
}

// The column drop zone itself
div.ag-column-drop {
  border-left: 1px solid $sq-overlay-gray;
  border-top: 1px solid $sq-overlay-gray;
  border-right: 1px solid $sq-overlay-gray;
}

[col-id='ag-Grid-AutoColumn'] span.ag-header-cell-text {
  font-weight: bold;
  margin-left: 4px;
}
