/**
This file contains only theme color specific css styling information.
If it's a shade of green or blue it should be added to this file.
No other css information should be added here.
**/

.fc-report.workbenchDisplay {
  color: white !important;
  background-color: $sq-report-color;
}

.fc-report-lock.workbenchDisplay {
  color: $sq-report-color !important;
}

.fc-analysis.workbenchDisplay {
  color: white !important;
  background-color: $sq-analysis-color;
}

.fc-analysis-lock.workbenchDisplay {
  color: $sq-analysis-color !important;
}

.fc-seeq-datalab.workbenchDisplay {
  color: white !important;
  background-color: $sq-datalab-color;
}

.fc-seeq-datalab-lock.workbenchDisplay {
  color: $sq-datalab-color !important;
}

.fa-folder-open.workbenchDisplay,
.fa-sharp-regular-folder-open-lock.workbenchDisplay {
  color: $sq-report-color !important;
}

.workbenchBtn {
  .fa-folder-open.workbenchDisplay,
  .fa-sharp-regular-folder-open-lock.workbenchDisplay {
    color: white !important;
    background-color: $sq-report-color;
  }
}

.fa-book.workbenchAnalysis.workbenchDisplay {
  color: $sq-analysis-color !important;
}

.fc-journal.workbenchAnalysis-lock.workbenchDisplay {
  color: $sq-analysis-color !important;
}

.fa-book.workbenchReport.workbenchDisplay {
  color: $sq-report-color !important;
}

.fc-journal.workbenchReport-lock.workbenchDisplay {
  color: $sq-report-color !important;
}

@each $theme, $themeColorDef in $themeColors {
  .color_#{$theme} {
    .highlightRightBorder {
      border-right-color: #{map-get($themeColorDef, dark)};
    }

    .highlightLeftBorder {
      border-left-color: #{map-get($themeColorDef, dark)};
    }

    .dividerBarIconWrapperRight,
    .dividerBarIconWrapper {
      &:hover {
        .dividerBarIconBg {
          background-color: #{map-get($themeColorDef, dark)};
          border-color: #{map-get($themeColorDef, dark)};
        }
      }
    }

    .browseColumnCollapsed {
      &:hover {
        .dividerBarIconBg {
          background-color: #{map-get($themeColorDef, dark)};
        }
      }
    }

    .browseColumn {
      .panelTabs {
        a {
          &.active {
            border-bottom: #{map-get($themeColorDef, dark)} 3px solid !important;
          }
        }
      }
    }

    .itemRowSelected {
      background-color: #{map-get($themeColorDef, itemRow)} !important;
    }

    .itemRow {
      &:hover,
      &:focus {
        background-color: #{map-get($themeColorDef, itemRow)} !important;
      }
    }

    .detailsPanelHeader {
      color: #{map-get($themeColorDef, dark)};
      font-weight: bold;
    }

    .activeWorksheetIndicator {
      background-color: #{map-get($themeColorDef, dark)};
    }

    .activeWorksheetBorder {
      border: solid 2px #{map-get($themeColorDef, dark)};
    }

    .activeWorksheetText {
      color: #{map-get($themeColorDef, dark)};
    }

    .link {
      color: #{map-get($themeColorDef, link)};
    }

    .sq-text-info,
    .sq-icon-info {
      color: #{map-get($themeColorDef, link)};
    }

    .sq-text-primary,
    .sq-icon-theme {
      color: #{map-get($themeColorDef, dark)};
    }

    .sq-icon-theme-light {
      color: #{map-get($themeColorDef, light)};
    }

    a {
      color: #{map-get($themeColorDef, link)};
    }

    .overlayPanel {
      box-shadow: 0 0 2px 2px #{map-get($themeColorDef, darker)};
    }

    .force-color {
      color: #{map-get($themeColorDef, dark)} !important;
    }

    .sq-bg-theme {
      background-color: #{map-get($themeColorDef, darker)};
    }

    .sq-light-theme-header > tr > th {
      background-color: #{map-get($themeColorDef, light)};
    }

    .sq-bg-theme-dark {
      background-color: #{map-get($themeColorDef, dark)};
    }

    // Bootstrap 4 has defaults for links without href/tabindex. Use these classes to override that behavior.
    // force-link-look also adds text-decoration: underline
    .force-link-color,
    .force-link-look {
      color: #{map-get($themeColorDef, link)} !important;
    }

    .sq-btn-theme {
      color: $white;
      border-color: transparent;
      background-color: #{map-get($themeColorDef, header)};

      &:focus,
      &:hover {
        border-color: #{map-get($themeColorDef, icon)};
        background-color: #{map-get($themeColorDef, icon)};
      }

      &:active,
      &:active:focus,
      &:active:hover {
        background-color: (lighten#{map-get($themeColorDef, icon)}, 3%) !important;
        border-color: #{map-get($themeColorDef, header)} !important;
      }
    }

    .sq-btn-theme-light {
      background-color: #{map-get($themeColorDef, icon)};
      border-color: #{map-get($themeColorDef, icon)};

      &:active,
      &:active:focus,
      &:active:hover {
        border-color: #{map-get($themeColorDef, link)};
      }
    }

    .sq-btn-outline {
      &:active,
      &:active:focus,
      &:active:hover {
        border-color: #{map-get($themeColorDef, dark)};
      }
    }

    .sq-btn-link {
      background-color: transparent;
      color: #{map-get($themeColorDef, link)};

      &:focus {
        outline: 0;
      }
    }

    .sq-btn-no-border {
      border-color: transparent;
    }

    .pagination {
      .page-item.active {
        .page-link {
          background-color: #{map-get($themeColorDef, dark)};
          border-color: #{map-get($themeColorDef, dark)};
        }
      }

      .page-item {
        .page-link {
          &:hover,
          &:focus {
            color: #{map-get($themeColorDef, dark)};
          }
        }
      }
    }

    /******************* Report Editor *******************/
    .reportDateModal {
      .displayRangeSelector {
        p {
          color: #{map-get($themeColorDef, link)};
        }

        .btn-link > i {
          color: #{map-get($themeColorDef, link)};

          &:hover {
            color: #{map-get($themeColorDef, dark)};
          }
        }

        #conditionEditor {
          ul.dropdown-menu {
            li.active > a {
              &,
              &:hover,
              &:focus {
                background: #{map-get($themeColorDef, link)};
              }
            }
          }
        }
      }
    }

    .reportConfigDateRangeHeader {
      background-color: #{map-get($themeColorDef, configHeader)};
    }

    .form-control:focus,
    .form-control:hover,
    input:focus,
    input.text:focus,
    input:hover,
    input.text:hover {
      border-color: #{map-get($themeColorDef, dark)};
    }

    .btn-default {
      &:active {
        border-color: #{map-get($themeColorDef, dark)} !important;
      }
    }

    .splashScreen {
      background-color: #{map-get($themeColorDef, dark)};
    }

    .fa-header-color {
      color: #{map-get($themeColorDef, dark)};
    }

    .card-primary {
      box-shadow: 0 0 1px 1px rgba(map-get($themeColorDef, dark), 0.36);
    }

    div.reportBackupPreview {
      .backArrow {
        color: #{map-get($themeColorDef, dark)};

        &:hover {
          color: #{map-get($themeColorDef, icon)};
        }
      }

      .dateDisplay {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .sq-btn-default {
      @extend .btn-default;

      &,
      &:active,
      &:focus,
      &:hover,
      &:active:focus,
      &:active:hover {
        color: #{map-get($themeColorDef, dark)};
        border-color: #{map-get($themeColorDef, darker)};
      }

      &:hover,
      &:focus {
        border-color: #{map-get($themeColorDef, icon)};
        background-color: #{map-get($themeColorDef, icon)};
        color: $white;
      }

      &:active:hover,
      &:active:focus {
        background-color: #{map-get($themeColorDef, icon)};
        color: $white;
      }
    }

    .sq-icon-hover {
      &:hover,
      &:focus {
        i {
          color: #{map-get($themeColorDef, icon)};
        }
      }
    }

    .reportBackups {
      div[uib-accordion-group] {
        .card-header {
          h4 {
            color: #{map-get($themeColorDef, link)};

            &:hover {
              color: #{map-get($themeColorDef, icon)};
            }
          }
        }

        .card-body {
          .versionEntry {
            &.selected {
              background-color: #{map-get($themeColorDef, icon)};
            }
          }
        }
      }
    }

    .linkHighlight {
      &:hover,
      &:focus {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .resizablePanel {
      .handle-right {
        &:hover,
        &:active,
        &:focus {
          > i {
            color: #{map-get($themeColorDef, dark)} !important;
          }
        }

        i {
          &:hover {
            color: #{map-get($themeColorDef, dark)} !important;
          }
        }
      }

      .handle-bottom {
        &:hover,
        &:active,
        &:focus {
          > i {
            color: #{map-get($themeColorDef, dark)} !important;
          }
        }

        i {
          &:hover {
            color: #{map-get($themeColorDef, dark)} !important;
          }
        }
      }
    }

    sq-license-expiration-warning {
      .closeIcon {
        &:hover {
          color: #{map-get($themeColorDef, dark)};
        }
      }
    }

    .license-management {
      .title {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .toolsSearchBar {
      &.active {
        border-bottom: 1px solid #{map-get($themeColorDef, icon)};
      }
    }

    .viewing-badge {
      &:hover {
        background-color: #{map-get($themeColorDef, dark)};
      }
    }

    .file-drop-zone-over {
      border: dashed 2px #{map-get($themeColorDef, dark)};
    }

    .user-admin,
    .group-admin {
      .modal-header {
        .modal-title {
          color: #{map-get($themeColorDef, dark)};
        }
      }

      i {
        color: #{map-get($themeColorDef, dark)};
      }

      .control-label {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    #formulaContainer {
      .highlightedText {
        color: #{map-get($themeColorDef, dark)};
      }

      .rg-right {
        &:hover {
          color: #{map-get($themeColorDef, dark)};
        }
      }

      .rg-bottom {
        &:hover {
          color: #{map-get($themeColorDef, dark)};
        }
      }

      .formulaHelp {
        h1,
        h2,
        h3 {
          color: #{map-get($themeColorDef, dark)};
        }
      }

      .aiAssistant {
        .user {
          background-color: #{map-get($themeColorDef, highlightBackground)};
        }

        .promptFooter {
          background-color: #{map-get($themeColorDef, highlightBackground)};
        }
      }
    }

    .sq-chart {
      .highcharts-hover-x-label {
        span {
          color: #{map-get($themeColorDef, dark)};
        }
      }
    }

    .worksheetViewSelector {
      background-color: #{map-get($themeColorDef, header)};

      &:hover {
        background-color: #{map-get($themeColorDef, icon)};
      }
    }

    .overlayPanel {
      form {
        div:not(.has-feedback) .control-label {
          color: #{map-get($themeColorDef, dark)};
        }
      }
    }

    .loginSlogan {
      color: #{map-get($themeColorDef, dark)};
    }

    .investigateRangeSelector,
    .displayRangeSelector {
      sq-date-time-entry,
      .dateTimeEntry,
      sq-duration-entry,
      .durationTimeEntry {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .folderAction {
      &:hover {
        color: #{map-get($themeColorDef, icon)};
      }
    }

    .btn-link {
      color: #{map-get($themeColorDef, link)};

      &:hover {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .dropdown-menu > li > a {
      &:hover,
      &:focus {
        background-color: #{map-get($themeColorDef, dark)};
      }
    }

    .dropdown-menu > .active > a {
      color: #{map-get($themeColorDef, dark)};
    }

    sq-user-profile.overlayPanel {
      box-shadow: 0 0 2px 2px rgba(map-get($themeColorDef, dark), 0.5);
    }

    .iconHover {
      &:hover {
        color: #{map-get($themeColorDef, icon)};
      }
    }

    .mainHeader {
      background-color: #{map-get($themeColorDef, header)};

      color: white;

      a {
        color: white;
      }

      .sq-navbar-default {
        &:hover {
          color: #{map-get($themeColorDef, icon)};
        }

        .sq-navbar-noninteractable {
          background-color: #{map-get($themeColorDef, dark)};
        }
      }

      .navbarHomeButton {
        background-color: #{map-get($themeColorDef, darker)};

        &:hover {
          color: #{map-get($themeColorDef, icon)};
        }
      }
    }

    sq-user-profile.overlayPanel {
      h4 {
        color: #{map-get($themeColorDef, dark)};
      }

      div:not(.has-feedback) .control-label {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .homeScreenToolCard {
      color: #{map-get($themeColorDef, link)};
      fill: #{map-get($themeColorDef, link)};
    }

    .mainFooter {
      color: #{map-get($themeColorDef, footerTextColor)};

      .browserRecommendation {
        color: #{map-get($themeColorDef, footerTextColor)};

        & a,
        & a:hover {
          color: #{map-get($themeColorDef, dark)};
          text-decoration: underline;
        }
      }
    }

    .workstepButton {
      &:hover:not([disabled='disabled']) {
        color: #{map-get($themeColorDef, icon)};
      }

      &:active:not([disabled='disabled']) {
        color: #{map-get($themeColorDef, icon)} !important;
      }
    }

    .btn-transparent {
      &:hover,
      &:active {
        background: #{map-get($themeColorDef, dark)};
      }
    }

    i.activatedAnalysis {
      color: $sq-icon-activated-analysis;
    }

    i.activatedTopic {
      color: $sq-icon-activated-topic;
    }

    .borderBottomPrimary {
      border-bottom: 1px solid #{map-get($themeColorDef, dark)};
    }

    .workbookAcl {
      ul.dropdown-menu {
        li.active > a {
          &,
          &:hover,
          &:focus {
            background: #{map-get($themeColorDef, dark)};
          }
        }
      }
    }

    .browseColumn {
      .icon {
        color: #{map-get($themeColorDef, dark)};
      }

      .toolbar .option .btn:hover,
      .toolbar .option .btn .active,
      .toolPanel {
        .icon {
          color: #{map-get($themeColorDef, icon)};
        }
      }

      .toolbar {
        .btn-default {
          color: #{map-get($themeColorDef, dark)};

          &:hover {
            border-color: #{map-get($themeColorDef, icon)};
          }
        }
      }

      .toolOverviewCard {
        .headerName {
          color: #{map-get($themeColorDef, dark)};
        }

        .headerIcon {
          color: #{map-get($themeColorDef, dark)};
        }

        .pluginToolSvgFill {
          fill: #{map-get($themeColorDef, dark)};
        }

        &:hover,
        &:focus {
          .tooltipIcon {
            color: #{map-get($themeColorDef, icon)};
          }
        }
      }

      .nav-tabs.nav-justified > li {
        &:not(.disabled):not(.active) {
          & > a {
            box-shadow: 6 px - 3 px 18 px - 6 px rgba(map-get($themeColorDef, dark), 1);
          }
        }
      }

      .card {
        box-shadow: none;

        h4,
        .h4 {
          color: #{map-get($themeColorDef, dark)};
        }

        sq-check-mark-or-number {
          .fa-check,
          .fa-plus-square,
          .fa-minus-square {
            color: #{map-get($themeColorDef, dark)};
          }
        }
      }
    }

    .btn-toolbar {
      &:hover {
        border-color: #{map-get($themeColorDef, darker)};
        color: #{map-get($themeColorDef, darker)};
      }

      & .active {
        border-color: #{map-get($themeColorDef, darker)};
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .licenseUploadIcon {
      color: #{map-get($themeColorDef, dark)};
    }

    .searchTitleInput {
      & input {
        color: #{map-get($themeColorDef, dark)};

        &:focus,
        &:hover {
          border-color: #{map-get($themeColorDef, dark)};
          box-shadow: inset 0 1px 1px rgba($sq-darkest-gray, 0.075), 0 0 8px rgba(map-get($themeColorDef, dark), 0.6);
        }
      }
    }

    .worksheetEditingOverlay {
      color: #{map-get($themeColorDef, icon)};
    }

    .workbookSlide {
      .workbookLabel {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .itemRow {
      .searchBtn {
        color: #{map-get($themeColorDef, link)};

        &:hover {
          color: #{map-get($themeColorDef, dark)};
        }
      }
    }

    sq-new-workbench-item {
      .h4 {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .activeOnHover:hover {
      color: #{map-get($themeColorDef, dark)};
    }

    .timebar {
      > svg {
        g.selector {
          rect {
            &.selector,
            &.leftSelector,
            &.rightSelector {
              stroke: #{map-get($themeColorDef, dark)};
            }
          }
        }
      }
    }

    .toolsSearchBar {
      &.active {
        border-bottom: 1px solid #{map-get($themeColorDef, dark)};
      }
    }

    .btn-inversed {
      @extend .btn-default;
      background-color: #{map-get($themeColorDef, dark)};
    }

    .btn-inversed:hover {
      background-color: #{map-get($themeColorDef, icon)};
    }

    .alert-info {
      border-color: #{map-get($themeColorDef, dark)};
    }

    .form-control {
      box-shadow: none;

      &:focus {
        box-shadow: inset 0 1px 2px 0 rgba(map-get($themeColorDef, dark), 0.075) !important;
      }
    }

    ul.searchBreadcrumbs {
      li {
        color: #{map-get($themeColorDef, link)};
      }

      .fc-usage {
        color: $sq-fairly-dark-gray !important;
      }
    }

    .folderAction,
    .text-interactive {
      color: #{map-get($themeColorDef, link)};

      &:focus,
      &:hover,
      &:active {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    input[type='checkbox']:focus + label::before {
      outline: #{map-get($themeColorDef, dark)} solid 1px;
    }

    input[type='radio']:focus + label::before {
      outline: #{map-get($themeColorDef, dark)} solid 1px;
    }

    .hoverBorder:hover,
    .focusBorder:focus {
      border: 1px solid #{map-get($themeColorDef, dark)} !important;
    }

    .modal-body .optionSelected {
      background-color: #{map-get($themeColorDef, link)};
    }

    .newBtnWorkbench {
      .workbookIconLarge {
        color: #{map-get($themeColorDef, dark)};
      }

      .h4 {
        color: #{map-get($themeColorDef, dark)};
      }
    }

    .homeScreenNav.current {
      border-left: 5px solid #{map-get($themeColorDef, dark)};
      background-color: #{map-get($themeColorDef, highlight)};
    }

    .homeScreenBreadcrumbLink,
    .breadcrumbLink {
      color: #{map-get($themeColorDef, dark)};
    }

    .corporateMessage {
      background-color: #{map-get($themeColorDef, highlight)};
    }

    .assetGroupHeader {
      background-color: #{map-get($themeColorDef, highlight)};
      border-bottom: 1px solid #{map-get($themeColorDef, dark)};
    }

    .selected-item-entry {
      background-color: #{map-get($themeColorDef, dark)} !important;
      color: white;

      > i,
      .itemAsset {
        color: white !important;
      }
    }

    .selectIdentity {
      .dropdown-item.active {
        background-color: #{map-get($themeColorDef, dark)};
      }
    }

    .alert-info-hover:hover {
      background-color: (darken#{map-get($themeColorDef, higlight)}, 5%);
    }

    .dropdown-menu a.dropdown-item {
      &:focus,
      &:active,
      &:hover {
        background-color: #{map-get($themeColorDef, dark)};
      }
    }

    .homeScreenHelpfulLink {
      &:hover {
        background-color: #{map-get($themeColorDef, highlight)};
      }
    }

    .CircularProgressbar-path {
      stroke: #{map-get($themeColorDef, dark)} !important;
    }

    .sq-slider::-moz-range-progress {
      background-color: #{map-get($themeColorDef, dark)};
    }

    .sq-slider::-ms-fill-lower {
      background-color: #{map-get($themeColorDef, dark)};
    }

    .sq-slider::-ms-thumb {
      &:hover,
      &:focus,
      &:active {
        background: #{map-get($themeColorDef, darker)};
      }
    }

    .sq-slider::-webkit-slider-thumb {
      background: #{map-get($themeColorDef, dark)};
    }

    .tourCarousel {
      .carousel-indicators {
        li.active {
          background-color: #{map-get($themeColorDef, dark)};
        }
      }
    }

    .timebarWrapper {
      .timebarContainer {
        .leftSelector,
        .rightSelector,
        .selector {
          border: 2px solid #{map-get($themeColorDef, 'link')};
        }

        .selector {
          border-color: #{map-get($themeColorDef, 'link')};
        }
      }
    }

    .displayRangeSelector {
      p {
        color: #{map-get($themeColorDef, 'dark')};
      }
    }

    .borderSelected {
      border: 2px solid #{map-get($themeColorDef, 'dark')} !important;
    }

    @keyframes themeColorFade {
      from {
        background: #{map-get($themeColorDef, light)};
      }
      to {
        background: transparent;
      }
    }
  }
}
