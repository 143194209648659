#psFormula {
  .CodeMirror {
    height: 100%;
    width: 100%;
  }
}

sq-formula {
  .CodeMirror {
    height: auto;
  }
}

.ui-codemirror {
  display: inline-block;

  // These are some of the styles applied to the .well class
  .CodeMirror {
    border-radius: $border-radius;
  }
}

.CodeMirror-lint-mark-error,
.CodeMirror-lint-mark-warning {
  background-position: left bottom;
  background-repeat: repeat-x;
}

// NOTE: These are not the same squiggle files found in lint.css, their colors have been changed
//
// The colors can be changed by creating a png file with the `base64` utility (remember to remove css line breaks
// first), changing the colors using a program like Photoshop, and then minifing the png and converting back with the
// `base64` command.
.CodeMirror-lint-mark-error {
  background-image: url('data:image/png;base64,\
iVBORw0KGgoAAAANSUhEUgAAAAQAAAADBAMAAABG2rP1AAAAElBMVEXdYl8AAADdYl/dYl/dYl/d\
Yl8fcv3kAAAABnRSTlNQAPbcjlprCIdmAAAAEUlEQVQI12MQNGBwZmJQEAUAA40AvC9uHSgAAAAA\
SUVORK5CYII=\
');
}

.CodeMirror-lint-mark-warning {
  background-image: url('data:image/png;base64,\
iVBORw0KGgoAAAANSUhEUgAAAAQAAAADBAMAAABG2rP1AAAAElBMVEXwqE0AAADwqE3wqE3wqE3w\
qE2bGk7lAAAABnRSTlNQAPbcjlprCIdmAAAAEUlEQVQI12MQNGBwZmJQEAUAA40AvC9uHSgAAAAA\
SUVORK5CYII=\
');
}

.CodeMirror-lint-message-warning,
.CodeMirror-lint-message-error {
  @extend .popover-body;
  white-space: pre;
  font-family: $font-family-monospace;
}

.CodeMirror-lint-message-warning {
  @extend .bg-warning;
}

.CodeMirror-lint-message-error {
  @extend .bg-danger;
}

.CodeMirror-lint-tooltip {
  position: fixed;
  opacity: 0;
  transition: opacity 0.4s;

  // These are select properties from the `.popover` class, so that we mimic that look
  z-index: $zindex-popover;
  padding: 1px;
  @include reset-text;
  font-size: $font-size-base;

  background-clip: padding-box;

  border-radius: $border-radius-lg;
}

// Make placeholder text lighter
.CodeMirror-empty {
  pre,
  pre * {
    color: $sq-fairly-dark-gray;
  }
}

// Seeq Code Mirror Theme
// Based off of the eclipse theme, see codemirror's theme folder for more examples
.cm-s-seeq span.cm {
  &-meta {
    color: #ff1717;
  }

  &-keyword {
    line-height: 1em;
    font-weight: bold;
    color: #7f0055;
  }

  &-atom {
    color: #219;
  }

  &-number {
    color: #164;
  }

  &-def {
    color: #00f;
  }

  &-variable {
    color: black;
  }

  &-variable-2 {
    color: #0000c0;
  }

  &-variable-3 {
    color: #0000c0;
  }

  &-property {
    color: black;
  }

  &-operator {
    color: black;
  }

  &-comment {
    color: #3f7f5f;
  }

  &-string {
    color: #2a00ff;
  }

  &-string-2 {
    color: #f50;
  }

  &-qualifier {
    color: #555;
  }

  &-builtin {
    color: #30a;
  }

  &-bracket {
    color: #cc7;
  }

  &-tag {
    color: #170;
  }

  &-attribute {
    color: #00c;
  }

  &-link {
    color: #219;
  }

  &-error {
    color: #f00;
  }
}

// Make sure CodeMirror autocomplete dropdown is placed in front of actual editor
.CodeMirror-hints {
  z-index: 10001;
  position: absolute;
  overflow: hidden;
  list-style: none;

  margin: 0;
  padding: 2px;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 1px solid silver;

  background: white;
  font-size: 90%;
  font-family: monospace;

  max-height: 20em;
  overflow-y: auto;
}

.CodeMirror-hint {
  margin: 0;
  padding: 0 4px;
  border-radius: 2px;
  white-space: pre;
  color: black;
  cursor: pointer;
}

li.CodeMirror-hint-active {
  background: #08f;
  color: white;
}

.tw-dark {
  .CodeMirror {
    color: black;
  }

  .CodeMirror-scrollbar-filler,
  .CodeMirror-gutter-filler {
    background-color: white; /* The little square between H and V scrollbars */
  }

  .CodeMirror-gutters {
    border-right: 1px solid $sq-darkest-gray_dark;
    background-color: $sq-tools-background_dark;
  }

  .CodeMirror-linenumber {
    color: $sq-text-color_dark;
  }

  .CodeMirror-guttermarker {
    color: white;
  }

  .CodeMirror-guttermarker-subtle {
    color: $sq-light-gray_dark;
  }

  .CodeMirror-cursor {
    border-left: 1px solid white;
  }

  .cm-s-seeq span.cm {
    &-meta {
      color: #ff1717;
    }

    &-keyword {
      color: #c460a2;
    }

    &-atom {
      color: #7467c7;
    }

    &-number {
      color: #469374;
    }

    &-def {
      color: rgb(151, 226, 189);
    }

    &-variable {
      color: white;
    }

    &-variable-2 {
      color: rgb(103, 150, 232);
    }

    &-variable-3 {
      color: #8d8db4;
    }

    &-property {
      color: white;
    }

    &-operator {
      color: white;
    }

    &-comment {
      color: #3f7f5f;
    }

    &-string {
      color: #5e4cb7;
    }

    &-string-2 {
      color: #f50;
    }

    &-qualifier {
      color: #816f6f;
    }

    &-builtin {
      color: rgb(138, 95, 230);
    }

    &-bracket {
      color: #cc7;
    }

    &-tag {
      color: #170;
    }

    &-attribute {
      color: #4040a2;
    }

    &-link {
      color: #365d81;
    }

    &-error {
      color: #f00;
    }
  }

  .CodeMirror-hints {
    border: $sq-border-color_dark;
    background: $sq-tools-background_dark;
  }

  .CodeMirror-hint {
    background-color: black;
    color: $sq-text-color_dark;
  }

  li.CodeMirror-hint-active {
    background: $sq-footer-color_dark;
    color: white;
  }
}
