/*!
 * Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
@import 'functions';
@import 'variables';

@font-face {
  font-family: 'Font Awesome Kit';
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/custom-icons.woff2') format('woff2'),
    url('#{$fa-font-path}/custom-icons.ttf') format('truetype');
}

.fak, .#{$fa-css-prefix}-kit {
  font-family: "Font Awesome Kit";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--#{$fa-css-prefix}-display, #{$fa-display});
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}

@each $name, $icon in $fa-custom-icons {
  .fak.#{$fa-css-prefix}-#{$name}:before, .fa-kit.#{$fa-css-prefix}-#{$name}:before { content: unquote("\"#{ $icon }\""); }
}
