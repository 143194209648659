/* Classes to help manipulate the layout of elements */
body,
.fullViewport {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -ms-overflow-style: none; // Prevent IE showing scrollbars on hover
}

// pdfMode class is added by the renderer when capturing a PDF. These overrides change the look as well as the layout
// of certain elements so that ag-grid will correctly auto-size itself
html.pdfMode {
  body,
  .fullViewport {
    height: auto;
    width: auto;
    overflow: visible; // Ensures all pages are printed
  }

  // Containers of an ag-grid table must be block for the table to be auto-sized correctly because ag-grid
  // looks at the width of the parent element to determine how much space to use for each column.
  // This cannot be in @media print because it needs to be applied before ag-grid starts rendering.
  .inlineBlock.seeqContentWrapper:has(.ag-root-wrapper) {
    display: block;
  }

  // Ensures that nested tables that span multiple pages display display correctly (CRAB-41727)
  .ck-content .table {
    &,
    .ck-editor__editable .ck-table-bogus-paragraph {
      display: block;
    }
  }

  // Ensure decimal and disc bullets are visible when lists are rendered in PDF view
  .fr-view li {
    overflow: visible !important;
  }

  // Ensure PDFs include background colors. Although this is a vendor-specific property that is not supported by all
  // browsers, it works for our case because we use Chromium on the server to generate all PDFs.
  * {
    -webkit-print-color-adjust: exact;
  }

  // Ensures all pages are printed and that every row of an interactive table is shown
  .flexFill {
    overflow: visible !important;
  }

  // Ensures the last row of interactive tables are shown and that bottom of the page is not cut off
  .reportEditor,
  .flexColumnContainer,
  .flexRowContainer {
    display: block !important;
  }
  .d-none {
    display: none !important;
  }

  .ck-pagination-view-line,
  ::-webkit-scrollbar {
    display: none;
  }

  // Override to use percent so that it prints all the pages
  .ckEditorMain .reportEditorWrapper .editorContainer {
    height: 100% !important;
  }

  // Strange workaround to make sure borders on tables don't disappear when printing
  .reportEditor .ck-content .table table {
    border-collapse: separate;
  }
}

.fixed {
  position: fixed;
}

.displayInline {
  display: inline !important;
}

.fullWidth {
  width: 100vw;
}

// For details, see https://stackoverflow.com/a/43669966
.inheritMinHeight {
  min-height: inherit;
}

.inheritMaxWidth {
  max-width: inherit;
}

.inheritHeight {
  height: inherit;
}

.maxWidth40Percent {
  max-width: 40%;
}

.maxWidth100Percent {
  max-width: 100%;
}

.maxWidth90Vw {
  max-width: 90vw;
}

// Disables animations during tests
.runningInProtractor .animated {
  animation-duration: 0s;
  transition-duration: 0s;
}

.runningInProtractor .modal.fade .modal-dialog,
.runningInProtractor .modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.runningInProtractor {
  .modal.fade,
  .tooltip.fade {
    opacity: 1;
  }
}

.runningInProtractor .modal.fade .modal-dialog,
.runningInProtractor .modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

#wsScrollBtns {
  a[disabled] {
    pointer-events: none;
  }
}

.worksheetsColumn {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  transition: width 0.5s ease;
  width: 130px;
  min-width: 130px;
  margin-left: 3px;

  .overflowAuto {
    overflow-x: hidden;
  }
}

.worksheetsColumn.collapsed {
  width: 12px !important;
  min-width: 12px !important;
}

.editableTextInput {
  &,
  &:hover,
  &:focus,
  &:active {
    outline: none;
    border: none;
  }
}

.editableTextResizer {
  visibility: hidden;
  height: 0;
  width: fit-content;
}

.editableText {
  position: relative;
  transition: 0.3s ease-in-out;
}

.editableTextHeight {
  position: absolute;
  visibility: hidden;
}

.collapsed {
  width: 0;
  margin: 0;
  padding: 0;
}

.legendGroupEvents {
  margin-top: 10px;
  margin-bottom: 10px;
}

.overflowAuto {
  overflow: auto;
  -ms-overflow-style: auto; // Ensure scrollbars appear when needed in IE 11 / Edge
}

.overflowYScroll {
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-x-style: hidden;
  -ms-overflow-y-style: auto;
}

.widerScrollbar {
  ::-webkit-scrollbar {
    width: 17px;
  }
}

.overflowHidden {
  overflow: hidden;
  -ms-overflow-x-style: hidden;
  -ms-overflow-y-style: hidden;
}

.overflowYAuto {
  overflow-y: auto !important;
  -ms-overflow-y-style: auto;
}

.overflowXHidden {
  overflow-x: hidden;
  -ms-overflow-x-style: hidden;
}

.displayTable {
  display: table;
  min-width: 100%;
}

.displayBlock {
  display: block;
}

.displayGrid {
  display: grid;
}

.tableWrapper {
  overflow: auto;
  flex: 1 1 auto;
}

.inlineFlex {
  display: inline-flex;
}

.forceInlineFlex {
  display: inline-flex !important;
}

.displayFlex {
  display: flex;
}

.flexColumnContainer {
  display: flex;
  flex-direction: row;
}

.flexColumnContainerWrap {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

.flexRowReverse {
  flex-direction: row-reverse;
}

.flexRowContainer {
  display: flex;
  flex-direction: column;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexNoWrap {
  flex-wrap: nowrap;
}

.flexCenter {
  justify-content: center;
  align-items: center;
}

.flexJustifyCenter {
  justify-content: center;
}

.flexAlignCenter {
  align-items: center;
}

.flexBaseline {
  align-items: baseline;
}

.flexSelfStretch {
  align-self: stretch;
}

.flexSelfCenter {
  align-self: center;
}

.flexSelfStart {
  align-self: flex-start;
}

.flexSelfEnd {
  align-self: flex-end;
}

.flexContentStart {
  align-content: flex-start;
}

.flexSpaceBetween {
  justify-content: space-between;
}

.flexSpaceAround {
  justify-content: space-around;
}

.flexJustifyInitial {
  justify-content: initial;
}

.flexJustifyStart {
  justify-content: flex-start;
}

.flexJustifyEnd {
  justify-content: flex-end;
}

.flexAlignStart {
  align-items: flex-start;
}

.flexAlignEnd {
  align-items: flex-end;
}

.marginAuto {
  margin: auto;
}

// margin-top: auto works by adding a top margin according to the height of the flex box, pushing the element
// to the bottom of the container.
// http://stackoverflow.com/questions/24697267/flexbox-column-align-self-to-bottom
.flexAlignBottom {
  margin-top: auto;
}

.flexFill {
  flex: 1 1 auto;
  // If a flex container contains a non-flex element (such as a fixed-size chart) that is bigger than the available
  // space it will overflow its boundaries.  By clipping the content we get an accurate size of the flex element which
  // allows the chart to then be resized to a size that fits.
  overflow: hidden;
}

.flexFillEqually {
  flex: 1 1 0%;
  overflow: hidden;
}

// For best results with Firefox do not use this on containers with scrolling content unless also combined with changing
// the overflow to hidden, scroll, auto, etc. Otherwise the scrollbars may not appear where they do in IE or Chrome
// Also see: CRAB-8218
.flexFillOverflow {
  flex: 1;
}

.flexDoubleFill {
  flex: 2;
}

.flexGrow {
  flex-grow: 1;
}

@each $num in (2 3 4) {
  .flexGrow-#{$num} {
    flex-grow: #{$num};
  }
}

.flexNoGrow {
  flex: 0 1 auto;
}

.flexNoShrink {
  flex-shrink: 0;
}

.flexNoGrowNoShrink {
  flex: 0 0 auto;
}

.flexGrowShrink {
  flex: 1 1 auto;
}

// min-width is needed for flexbox to behave properly with overflow
// see http://stackoverflow.com/a/12131365/1108708
.flexNoOverflow {
  min-width: 0px;
  overflow: hidden;
}

.flexWithoutOverflow {
  min-width: 0px;
}

// This is needed to make IE properly grow to display content with flexFill
// because IE interprets 0% as 0px while the standard is to interpret it as auto.
.flexBasisAuto {
  flex-basis: auto;
}

.flexBasisContent {
  flex-basis: content;
}

.flexBasisZero {
  flex-basis: 0;
}

.flexBasis100Percent {
  flex-basis: 100%;
}

@each $num in (50 100 200 250 300 400 500 600 700 800) {
  .flexBasis#{$num} {
    flex-basis: #{$num}px;
  }
}

.forceNoBottomMargin {
  margin-bottom: 0 !important;
}

@each $num in (0 1 2 3 4 5 6 7 8 9 10 12 15 18 20 21 25 27 30 35 40 45 50 55 65 70 100) {
  .m#{$num} {
    margin: #{$num}px;
  }
  @each $suffix, $style in (t: top, r: right, b: bottom, l: left) {
    .m#{$suffix}#{$num} {
      margin-#{$style}: #{$num}px;
    }
  }
  .top-#{$num} {
    top: #{$num}px;
  }
  .right-#{$num} {
    right: #{$num}px;
  }
  .bottom-#{$num} {
    bottom: #{$num}px;
  }
  .left-#{$num} {
    left: #{$num}px;
  }

  .mlr#{$num} {
    margin-left: #{$num}px;
    margin-right: #{$num}px;
  }

  .mtb#{$num} {
    margin-top: #{$num}px;
    margin-bottom: #{$num}px;
  }

  .gap#{$num} {
    gap: #{$num}px;
  }
}

.mt05 {
  margin-top: 0.5px;
}

.mt2point5 {
  margin-top: 2.5px;
}

// For negative margins
@each $num in (10 6 5 4 3 2 1) {
  .mn#{$num} {
    margin: #{$num * -1}px;
  }
  @each $suffix, $style in (t: top, r: right, b: bottom, l: left) {
    .m#{$suffix}n#{$num} {
      margin-#{$style}: #{$num * -1}px;
    }
  }
  .top-n#{$num} {
    top: #{$num * -1}px;
  }
  .right-n#{$num} {
    right: #{$num * -1}px;
  }
  .bottom-n#{$num} {
    bottom: #{$num * -1}px;
  }
  .left-n#{$num} {
    left: #{$num * -1}px;
  }
}

@each $num in (1 2 3 4 5 6 7 8 9 10 11 12 15 20 25 30 45 50 65 70 100 125 0) {
  .p#{$num} {
    padding: #{$num}px;
  }
  @each $suffix, $style in (t: top, r: right, b: bottom, l: left) {
    .p#{$suffix}#{$num} {
      padding-#{$style}: #{$num}px;
    }

    .p#{$suffix}#{$num}-important {
      padding-#{$style}: #{$num}px !important;
    }
  }

  .plr#{$num} {
    padding-left: #{$num}px;
    padding-right: #{$num}px;
  }

  .ptb#{$num} {
    padding-top: #{$num}px;
    padding-bottom: #{$num}px;
  }
}

@each $num in (4 0 6) {
  @each $suffix, $style in (tl: 'top-left', tr: 'top-right', bl: 'bottom-left', br: 'bottom-right') {
    .border-radius-#{$num} {
      border-radius: #{$num}px;
    }
    .b#{$suffix}r#{$num} {
      border-#{$style}-radius: #{$num}px;
    }
  }
}

.width-minimum {
  width: 1%;
}

.width-maximum {
  width: 100%;
}

.width-auto {
  width: auto;
}

.height-maximum {
  height: 100%;
}

.min-height-maximum {
  min-height: 100%;
}

.max-height-4em {
  max-height: 4em;
}

.max-height-20em {
  max-height: 20em;
}

@each $num
  in (
    0
      1
      5
      8
      10
      12
      15
      18
      20
      22
      23
      25
      27
      30
      32
      34
      35
      40
      45
      47
      50
      55
      60
      65
      70
      75
      80
      82
      90
      100
      110
      115
      120
      125
      130
      135
      140
      145
      150
      160
      175
      180
      195
      200
      220
      230
      250
      260
      270
      275
      300
      340
      360
      370
      390
      400
      425
      450
      500
      520
      600
      650
      750
      780
      800
      900
      1000
      1300
      0
  )
{
  .width-#{$num} {
    width: #{$num}px !important;
  }
  .min-width-#{$num} {
    min-width: #{$num}px !important;
  }
  .max-width-#{$num} {
    max-width: #{$num}px !important;
  }
  .height-#{$num} {
    height: #{$num}px !important;
  }
  .min-height-#{$num} {
    min-height: #{$num}px !important;
  }
  .max-height-#{$num} {
    max-height: #{$num}px !important;
  }
}

.border-right-0 {
  border-right: 0;
}

.width-25percent {
  width: 25%;
}

.width-90percent {
  width: 90%;
}

.width-100percent {
  width: 100%;
}

.width-min-content {
  width: min-content;
}

.connectionRow > td:nth-child(2) > i {
  float: left;
  padding-top: 2px;
}

.connectionRow > td:nth-child(2) > div {
  margin-left: 16px;
}

.panelTableHeader {
  height: 26px;
}

.fixedHeaderTable {
  position: relative;
  border-collapse: collapse;

  th {
    position: sticky;
    top: 0;
    z-index: $fixed-header-table-z-index;
  }

  //tables created by table builder do not use <th> because of transposed header
  thead tr:first-child td {
    position: sticky;
    z-index: $fixed-header-table-z-index;
    background: white;
  }

  // the DND indicator is an absolute div inside the td
  td {
    position: relative;
  }

  // the first td of the tr should have a higher z-index so that we can show the DND target indicator over all tds
  thead tr:first-child td:first-child {
    z-index: $fixed-header-table-z-index + 1;
  }
}

.fixedColumnTable {
  position: relative;
  border-collapse: collapse;

  td:first-child {
    position: sticky;
    z-index: $fixed-header-table-z-index;
    background: white;
  }

  // the DND indicator is an absolute div inside the td
  td {
    position: relative;
  }

  // the first td of the tr should have a higher z-index so that we can show the DND target indicator over all tds
  thead tr:first-child td:first-child {
    z-index: $fixed-header-table-z-index + 1;
  }
}

.borderTopNoneImportant {
  border-top: none !important;
}

.inline {
  display: inline !important;
}

.inlineBlock {
  display: inline-block;
}

.minWidthZero {
  min-width: 0;
}

.transitionNone {
  transition: none !important;
}

.overflowVisible {
  overflow: visible;
}

.verticalAlignMiddle {
  vertical-align: middle;
}

.forceVerticalAlignMiddle {
  vertical-align: middle !important;
}

.verticalAlignTextTop {
  vertical-align: text-top;
}

.tooltip-inner {
  max-width: 400px !important;
}

* > .fa.fa-hover-show,
*:hover > .fa.fa-hover-hidden {
  display: none;
}

*:hover > .fa.fa-hover-show {
  display: inline-block;
}

.indented {
  padding-left: 30px;
  padding-top: 5px;
}

.negativeVerticalMargin {
  margin: -0.8em 0;
}

.positionRelative {
  position: relative;
}

.positionAbsolute {
  position: absolute;
}

.forceFont {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.folderActions {
  display: none;
}

.homeScreenMainPanel > div {
  height: 100%;
}

.homeScreenHeight {
  height: 98%;
}

.homeScreenTable {
  min-height: 120px;
}

.homeScreenTable,
.homeScreenAdvancedSearch {
  .btn-default {
    background: none;
    border: 0;
    padding: 0;
  }

  .open > .btn-default.dropdown-toggle {
    background: none;
    border: 0;
  }
}

.hoverTable > tr:hover .folderActions {
  display: inline-block;
}

.th {
  font-weight: 600;
}

.wrapText {
  white-space: normal;
  text-align: left;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.noBorder {
  border: 0;
  border-radius: 0;
}

.newBtnWorkbench {
  .dropdown-menu > li > a {
    padding: 0;
  }
}

.noContentImgWrapper {
  width: 35%;
}

.homeScreenSearchBar,
.explorerModalSearchBar {
  padding: 0 !important;
  height: 34px !important;
}

.homeScreenNoBorderRadiusLeft {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.homeScreenSearchInput,
.explorerModalSearchInput {
  border: 0;
  height: 100% !important;
}

.forceZeroPadding {
  padding: 0 !important;
}

.forceNoBorder {
  border: none !important;
  background: none !important;
}

.greyPopover {
  @extend .popover;
  background-color: #eee;

  &.top > .arrow:after {
    border-top-color: #eee;
  }

  &.right > .arrow:after {
    border-right-color: #eee;
  }

  &.bottom > .arrow:after {
    border-bottom-color: #eee;
  }

  &.left > .arrow:after {
    border-left-color: #eee;
  }
}

.advancedPopover {
  position: absolute;
  z-index: 9998;
  top: 35px;
  right: 0;
  left: 0;
  border-radius: 4px;
}

.advancedPopoverAdvancedSearch {
  position: absolute;
  z-index: 1000;
  top: 35px;
  right: 0;
  left: 0;
  border-radius: 4px;
}

.homeScreenSearchFields {
  position: relative;
  margin-right: 2px;
}

.homeScreenLeftPanel {
  width: 400px;
  border-right: 1px solid;
  height: 100%;
  padding-left: 30px;
}

.clearFilterBtn {
  right: 18px;
  top: 8px;
}

.enterFilterBtn {
  right: 40px;
  top: 8px;
}

.circle {
  border-radius: 50%;
}

.sqGrayBox {
  @extend .border-radius-4;
}

.blackout {
  z-index: 5;
}

.noCoverInBlackout {
  z-index: 6;
}

.CodeMirror {
  height: auto;
}

.formulaFromScratch #formulaEditor {
  max-height: 440px;
}

#formulaEditor .CodeMirror {
  max-height: 100%;
  height: 100%;
}

.react-codemirror2 {
  height: 100%;
}

.default-layer {
  z-index: 0 !important;
}

.formulaShowHelp {
  margin-left: -15px;
  margin-right: 7px;
  margin-top: 7px;
  width: 75px;
}

#formulaContainer {
  form {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    overflow: hidden;
    height: 100%;
  }
}

.forceFlex {
  display: flex !important;
}

.iconLeft {
  padding-left: 4px;
  padding-top: 4px;
}

.bulkEditArrows {
  position: absolute;
  right: 3px;
  top: 48%;
  padding: 5px;
}

.footerWithFeedback {
  padding-left: 135px !important;
}

.hidden,
.invisible {
  visibility: hidden;
}

.autoMarginLeft {
  margin-left: auto;
}

.z102 {
  z-index: 102;
}

.datasourcesFilterCard,
.agentsFilterCard,
.tab-content {
  .input-group-text {
    padding: 0px !important;
    height: 34px !important;

    .sq-btn {
      height: 100%;
    }
  }
}

.createInputGroup,
.copyableInputGroup {
  .input-group-text {
    padding: 0px !important;
    height: 34px !important;

    .sq-btn {
      height: 100%;
    }
  }
}

.selectItem_itemName {
  font-size: 13px;
}

.fontWeightNormal {
  font-weight: normal;
}

.no-border {
  border: 0 !important;
}

.icon-button {
  &:hover,
  &:active {
    display: inline !important;
  }
}
